import { template as template_2a34b7a279ea4446b63868b6ee0a3785 } from "@ember/template-compiler";
const FKLabel = template_2a34b7a279ea4446b63868b6ee0a3785(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
