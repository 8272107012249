import { template as template_7b23d43464f9431ea95d4f72d9093d4e } from "@ember/template-compiler";
const WelcomeHeader = template_7b23d43464f9431ea95d4f72d9093d4e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
