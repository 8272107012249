import { template as template_af4838c55aaf467c9ae28d2f8fb1b96a } from "@ember/template-compiler";
import SearchMenuPanel from "../search-menu-panel";
const SearchMenuWrapper = template_af4838c55aaf467c9ae28d2f8fb1b96a(`
  <div class="search-menu glimmer-search-menu" aria-live="polite" ...attributes>
    <SearchMenuPanel @closeSearchMenu={{@closeSearchMenu}} />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SearchMenuWrapper;
