import { template as template_ba043c1e99f545be85b11608f1d231f5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_ba043c1e99f545be85b11608f1d231f5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
